<template>
    <div>
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"><i class="nav-icon fas fa-store-alt"></i> Busca de Lojas <small>Formulário de Cadastro</small></h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard">
                                    <i class="nav-icon fas fa-home"></i> Home
                                </router-link>
                            </li>
                            <li class="breadcrumb-item active"><i class="nav-icon fas fa-store-alt"></i> Busca de Lojas</li>
                        </ol>
                    </div>
                    <!-- /.col -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="card card-primary card-outline">
                    <form id="form1" class="form-horizontal">
                        <div class="card-body">
                            <div class="col-xs-12 col-sm-12 col-lg-6">
                                <h4>Dados do Busca Lojas</h4>
                                <div class="form-group row">
                                    <label for="endereco" class="col-sm-2 col-form-label">Endereço</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" id="endereco" name="endereco" v-model="entity.endereco" size="50" maxlength="250" placeholder="Endereço" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="numero" class="col-sm-2 col-form-label">Nro</label>
                                    <div class="col-sm-3">
                                        <input type="text" class="form-control" id="numero" name="numero" v-model="entity.numero" size="20" maxlength="25" placeholder="Nro" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="bairro" class="col-sm-2 col-form-label">Bairro</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" id="bairro" name="bairro" v-model="entity.bairro" size="50" maxlength="250" placeholder="Bairro" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="cidade" class="col-sm-2 col-form-label">Cidade</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" id="cidade" name="cidade" v-model="entity.cidade" size="50" maxlength="250" placeholder="Cidade" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="uf" class="col-sm-2 col-form-label">UF</label>
                                    <div class="col-sm-2">
                                        <input type="text" class="form-control" id="uf" name="uf" v-model="entity.uf" size="2" maxlength="2" placeholder="UF" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="pais" class="col-sm-2 col-form-label">Pais</label>
                                    <div class="col-sm-6">
                                        <input type="text" class="form-control" id="pais" name="pais" v-model="entity.pais" size="50" maxlength="150" placeholder="País" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="pais" class="col-sm-2 col-form-label">CEP</label>
                                    <div class="col-sm-2">
                                        <input type="text" class="form-control" id="cep" name="cep" v-model="entity.cep" size="50" maxlength="150" placeholder="CEP" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="card-footer">
                        <div class="offset-sm-1 col-sm-11">
                            <button type="button" v-if="this.$route.params.id == 0" v-on:click="insertEntity" class="btn btn-success btn-social"> <i class="fas fa-check"></i> Inserir</button>
                            <button type="button" v-if="this.$route.params.id != 0" v-on:click="updateEntity" class="btn btn-info btn-social"> <i class="fas fa-pencil-alt"></i> Alterar</button>
                            <button type="button" v-if="this.$route.params.id != 0" v-on:click="deleteEntity" class="btn btn-danger btn-social"> <i class="fas fa-times"></i> Excluir</button>
                            <button type="button" v-on:click="back" class="btn btn-default btn-social"><i class="fas fa-history"></i> Voltar</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BuscaLojaController from "@/controllers/busca_loja.controller";

    export default {
        data: function () {
            return {
                entity: {}
            };
        },
        mounted() {
            this.$validator.validate("#form1");
            const id = parseInt(this.$route.params.id, 10);
            this.loadEntity(id);
            $(":input").inputmask();
        },
        methods: {
            loadEntity: function (id) {

                if (id === 0)
                    return;

                BuscaLojaController.getById(id)
                    .then(response => {
                        this.entity = response.data.result;
                    });
            },
            insertEntity: function (event) {
                if (!this.$validator.isValid("#form1"))
                return;

                event.target.disabled = true;

                let vm = this;

                BuscaLojaController.insert(vm.entity)
                    .then(response => {
                        if (!response.data.success) {
                            vm.$toastr.error(response.data.error, 'Erro!', {
                                timeOut: 8000,
                                onHidden: function () {
                                    event.target.disabled = false;
                                }
                            });
                            return;
                        }

                        vm.$toastr.success("Dados cadastrados com sucesso.", "Aviso",
                            {
                                onHidden: function () {
                                    event.target.disabled = false;
                                    vm.back();
                                }
                            }
                        );
                    })
                    .catch(e => {
                        event.target.disabled = false;
                        vm.$toastr.error(e, 'Erro!', { timeOut: 8000 });
                    });
            },
            updateEntity: function (event) {
                if (!this.$validator.isValid("#form1"))
                return;

                event.target.disabled = true;

                let vm = this;

                BuscaLojaController.update(vm.entity)
                    .then(response => {
                        if (!response.data.success) {
                            vm.$toastr.error(response.data.error, 'Erro!', {
                                timeOut: 8000,
                                onHidden: function () {
                                    event.target.disabled = false;
                                }
                            });
                            return;
                        }

                        vm.$toastr.success("Dados alterados com sucesso.", "Aviso",
                            {
                                onHidden: function () {
                                    event.target.disabled = false;
                                    vm.back();
                                }
                            });
                    })
                    .catch(e => {
                        event.target.disabled = false;
                        vm.$toastr.error(e, 'Erro!', { timeOut: 8000 });
                    });
            },
            deleteEntity: function (event) {
                event.target.disabled = true;

                let vm = this;

                vm.$dialogs.confirm({
                    title: 'Confirmar Exclusão!',
                    content: 'Deseja realmente excluir estes dados?',
                    icon: 'fa fa-exclamation-triangle ',
                    type: 'red',
                    buttons: {
                        confirm: {
                            text: 'Confirmar',
                            btnClass: 'btn-red',
                            action: function () {
                                event.target.disabled = true;

                                BuscaLojaController.delete(vm.entity)
                                    .then(response => {

                                        if (!response.data.success) {
                                            vm.$toastr.error(response.data.error, 'Erro!', {
                                                timeOut: 8000,
                                                onHidden: function () {
                                                    event.target.disabled = false;
                                                }
                                            });
                                            return;
                                        }

                                        vm.$toastr.success("Dados excluídos com sucesso.", "Aviso",
                                            {
                                                onHidden: function () {
                                                    vm.back();
                                                }
                                            }
                                        );
                                    })
                                    .catch(e => {
                                        vm.$toastr.error(e, 'Erro!', { timeOut: 8000 });
                                        event.target.disabled = false;
                                    });
                            }
                        },
                        close: {
                            text: 'Cancelar'
                        }
                    }
                });
                event.target.disabled = false;
            },
            back: function () {
                this.$router.push({ name: "list-busca_loja" });
            }
        }
    };
</script>

<style scoped>
</style>