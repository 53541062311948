import http from '@/utils/http';

export default {
  async getById(id) {
    return await http.get(`/api/busca_loja/get/${id}`);
  },
  async insert(data) {
    return await http.post('/api/busca_loja/insert', data);
  },
  async update(data) {
    return await http.post('/api/busca_loja/update', data);
  },
  async delete(data) {
    return await http.post('/api/busca_loja/delete', data);
  }
};